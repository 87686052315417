
import {computed, defineComponent, ref} from "vue";
import { dateConvert } from "@/core/filters/datatime";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import { hideModal } from "@/core/helpers/dom";
import {jsPDF} from "jspdf";
import UploadFile from "@/components/appcomps/uploadfiles/UploadFile.vue";
import SendEmail from "@/components/appcomps/sendemail/SendEmail.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface voucherItem {
    Description: string;
    ManuelEmail: string;
    OperationEmail: string;
}

export default defineComponent({
    name: "reservation-hotel-voucher-modal2",
    components: {
        UploadFile,
        SendEmail,
    },
    methods: {
        setHotel(prmReservation, prmHotel, prmPaxes) {
            console.log("HotelVoucherModal setHotel START "+JSON.stringify(prmReservation));
            console.log("HotelVoucherModal setHotel START "+JSON.stringify(prmHotel));
            console.log("HotelVoucherModal setHotel START prmPaxes: "+JSON.stringify(prmPaxes));
            this.theReservation = prmReservation;
            this.theHotel = prmHotel;
            this.arrPaxes = prmPaxes;

            store.dispatch(Actions.HOTEL_DETAILS, {ID: this.theHotel.RemarksINT1});
        },
    },
    setup(props, {emit} ) {

        var theReservation = ref({});
        var theHotel = ref({});
        var arrPaxes = ref ([]);

        const arrCheckedEmails = ref([]);

        const refModal = ref<null | HTMLElement>(null);
        const refBtnExportToPdf = ref(null);
        const refBtnSendEmail = ref(null);
        const refFileUpload = ref(null);
        const refSendEmail = ref(null);

        const submitButtonRefConfirm = ref<null | HTMLButtonElement>(null);
        const submitButtonRefChange = ref<null | HTMLButtonElement>(null);
        const submitButtonRefCancel = ref<null | HTMLButtonElement>(null);

        var pWindow;

        const theHotelDetails = computed(() => {
            return store.getters.hotelDetails;
        });

        const voucherItem = ref<voucherItem>({
            Description: "",
            ManuelEmail: "",
            OperationEmail: "operasyon@cyprusroyal.com",
        });

        function getReservationEmail(prmResEmail) {
            let rv = [prmResEmail];

            return rv;
        }

        function getIsChecked(prmID) {
            //let vvShowAccount = "inputShowHideAccountInfo" + prmProductID;
            const inpEl = document.getElementById(prmID) as HTMLInputElement;
            if(inpEl.checked){
                return "true";
            }
            return "false";
        }

        function showVoucherEmail() {
            (document.getElementById("divEmailHotelVoucherOption") as HTMLElement).style.setProperty("display", "block", "important");
            //(document.getElementById("divConfirmButtonSection") as HTMLElement).style.setProperty("display", "none", "important");
        }

        function hideVoucherEmail() {
            (document.getElementById("divEmailHotelVoucherOption") as HTMLElement).style.setProperty("display", "none", "important");
            //(document.getElementById("divConfirmButtonSection") as HTMLElement).style.setProperty("display", "block", "important");
        }



        function clickConfirm(prmProductID, prmEmailKind){

            if (!submitButtonRefConfirm.value && !submitButtonRefChange.value && !submitButtonRefCancel.value) {
                return;
            }

            let arrIds = [];
            let arrEmails = [];
            arrIds.push(prmProductID);
            arrCheckedEmails.value=[];

            let vvClassName = "inputSelectReservationEmail" + prmProductID;
            const arrElements = document.getElementsByClassName(vvClassName);
            let vvID = "inputSelectManuelEmail" + prmProductID;

            let vvOperationMail = "inputSelectOperationEmail" + prmProductID;
            const operationElements = document.getElementById(vvOperationMail) as HTMLInputElement;

            if(operationElements.checked){
                arrEmails.push(voucherItem.value.OperationEmail);
            }

            const manuelElements = document.getElementById(vvID) as HTMLInputElement;


            if(manuelElements.checked){
                arrEmails.push(voucherItem.value.ManuelEmail);
            }


            for(let i=0; i<arrElements.length; i++){
                let eachElemnt = arrElements[i] as HTMLInputElement;
                if (eachElemnt.checked) {
                    // arrCheckedEmails.value.push(getProductByProductID(eachElemnt.getAttribute("productID")));
                    console.log("ReservationListVoucherModal clickConfirm " +JSON.stringify(arrCheckedEmails.value));
                    arrEmails.push(eachElemnt.getAttribute("email"));
                }

            }



            if(arrEmails.length>0){
                console.log("ReservationModal clickConfirm v1");
                // store.dispatch(Actions.EXPORT_TO_PDF, {TemplateCode: "HotelVoucher", Ids: arrIds}).then((prmData) => {
                //store.dispatch(Actions.EXPORT_TO_PDF, {TemplateCode_Email: "HotelVoucher", TemplateCode_Pdf: "HotelVoucher_Pdf", Ids: arrIds}).then((prmData) => {
                //store.dispatch(Actions.EXPORT_TO_PDF, {TemplateCode: "HotelVoucher", Ids: arrIds, testEmail: "hasanporklu@gmail.com" }).then((prmData) => {
                store.dispatch(Actions.EXPORT_TO_PDF, {
                    TemplateCode_Email: "HotelVoucher_Email",
                    TemplateCode_Pdf: "HotelVoucher_Pdf",
                    Ids: arrIds,
                    Emails: arrEmails,
                    testEmails: ["operasyon@cyprusroyal.com"], //["accinocoin@gmail.com"] ,
                    EmailKind:prmEmailKind,
                    Description:voucherItem.value.Description,
                    IsShowAccount: getIsChecked("inputShowHideAccountInfo" + prmProductID),
                    IsShowPayment:getIsChecked("inputShowHidePriceInfo" + prmProductID),
                }).then((prmData)=>{
                    if(prmEmailKind==="CONFIRM"){
                        submitButtonRefConfirm.value.disabled = false;
                        submitButtonRefConfirm.value.setAttribute("data-kt-indicator", "off");
                    }
                    if(prmEmailKind==="CHANGE"){
                        submitButtonRefChange.value.disabled = false;
                        submitButtonRefChange.value.setAttribute("data-kt-indicator", "off");
                    }
                    if(prmEmailKind==="CANCEL"){
                        submitButtonRefCancel.value.disabled = false;
                        submitButtonRefCancel.value.setAttribute("data-kt-indicator", "off");
                    }
                    if (prmData.data!==undefined) {
                        let isErrorExist = false;
                        let vvSendEmail = "";

                        for (let i=0; i<prmData.data.length; i++) {
                            let vvEach = prmData.data[i];
                            console.log(vvEach);
                            console.log(vvEach.result);
                            if (vvEach.result!=="success") {
                                isErrorExist = true;
                            } else {
                                vvSendEmail = vvEach.email;
                            }
                        }
                        let vvText = "Tebrikler. "+vvSendEmail+" adresine başarılı bir şekilde "+prmData.data.length+" adet eposta gönderildi";

                        if (!isErrorExist) {
                            Swal.fire({
                                text: vvText, confirmButtonText: "Geri Dön!",
                                icon: "success", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-primary",},
                            }).then(function () {
                                location.reload();
                            });
                        } else {
                            libTools.method.swalError("Tamam", "Bazı Email bilgileri gönderilemedi");
                        }



                    }
                }).catch((prmData) => {
                    console.log("ReservationModal clickConfirm v8");
                    libTools.method.swalError("Tamam", "Hata Oluştu");
                });
                if(prmEmailKind==="CONFIRM"){
                    submitButtonRefConfirm.value.disabled = false;
                    submitButtonRefConfirm.value.setAttribute("data-kt-indicator", "on");
                }
                if(prmEmailKind==="CHANGE"){
                    submitButtonRefChange.value.disabled = false;
                    submitButtonRefChange.value.setAttribute("data-kt-indicator", "on");
                }
                if(prmEmailKind==="CANCEL"){
                    submitButtonRefCancel.value.disabled = false;
                    submitButtonRefCancel.value.setAttribute("data-kt-indicator", "on");
                }
            }else{
                libTools.method.swalError("Tamam", "Email seçiniz.");
            }




        }








        function printModal() {

            var divContents = document.getElementById("divHotel_Voucher_AllPrintable").innerHTML;
            pWindow = window.open('', '', '');
            pWindow.document.write('<html><body>');
            pWindow.document.write(divContents);
            pWindow.document.write('</body></html>');
            pWindow.document.close();

            setTimeout(()=>{pWindow.print();}, 500);

            /*
            let nwindow=window.open();
            nwindow.document.write((document.getElementById("divHotel_Voucher_AllPrintable") as HTMLElement).innerHTML);
            nwindow.print();
            nwindow.close();
             */



            //hideModal(refModal.value);

            /*
            var printContents = document.getElementById("divHotel_Voucher_AllPrintable").innerHTML;
            var originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
             */

        }

        function click_ExportToPdf(prmType, prmMailTo) {
            let elTarget = document.getElementById("divHotel_Voucher_AllPrintable");
            elTarget.innerHTML = libTools.method.convertTurkishToEnglish(elTarget.innerHTML);

            if (prmType==="Export") {
                refBtnExportToPdf.value.disabled = true;
                refBtnExportToPdf.value.setAttribute("data-kt-indicator", "on");
            } else {
                refBtnSendEmail.value.disabled = true;
                refBtnSendEmail.value.setAttribute("data-kt-indicator", "on");
            }

            setTimeout(()=>{ exportToPdf(prmType, prmMailTo); }, 500);
        }

        function click_SendEmail() {
            (refSendEmail.value as any).showComp((theHotelDetails.value as any).ReservationEmail);
        }

        function emailEntered(prmEmail) {
            click_ExportToPdf("SendEmail", prmEmail);
        }

        function exportToPdf(prmType, prmMailTo) {
            const doc = new jsPDF('l', 'mm', [1150, 1100]);
            let elTarget = document.getElementById("divHotel_Voucher_AllPrintable");
            //let elNew = document.createElement("div");
            //elNew.innerHTML = libTools.method.convertTurkishToEnglish(vvText.innerHTML);
            doc.html(elTarget,
                {
                    callback: function(doc) {
                        let vvRes = theReservation.value as any;
                        let vvHotel = theHotel.value as any;
                        let vvDirName = vvRes.VoucherNo.replaceAll(".", "-");
                        let vvFileName = "HotelVoucher"+"_"+vvRes.VoucherNo.replaceAll(".", "_") + "_" + vvHotel.ID+".pdf";
                        let vvMailTo = prmMailTo;
                        let vvContent = "Merhabalar, Otel Voucher ("+vvHotel.Title+") bilgileri ektedir";
                        let vvToName = "Hotel Voucher "+vvHotel.ID;
                        let vvFromName = "Hotel Voucher "+vvHotel.ID;
                        let vvSubject = "Hotel Voucher "+vvHotel.ID;

                        if (prmType==="Export") {
                            doc.save(vvFileName);
                            refBtnExportToPdf.value.disabled = false;
                            refBtnExportToPdf.value.setAttribute("data-kt-indicator", "off");
                        }
                        if (prmType==="SendEmail") {
                            var vvFile = doc.output('blob');
                            (refFileUpload.value).addFileAndSendEmail(vvFile, vvDirName, vvFileName, "HOTELVOUCHER", vvMailTo, vvToName, vvFromName, vvSubject, vvRes.AccountID, vvRes.ID, vvContent);

                        }

                    },
                    x: 10,
                    y: 4,
                    image: { type: "png", quality: 0.98 }, // as per req
                    html2canvas: { letterRendering: true, useCORS: true },
                }
            );
        }

        function onSuccessUploadFile() {
            refBtnSendEmail.value.disabled = false;
            refBtnSendEmail.value.setAttribute("data-kt-indicator", "off");
            emit("on-success", theHotel.value);
        }

        function onErrorUploadFile(prmErrorCode) {
            refBtnSendEmail.value.disabled = false;
            refBtnSendEmail.value.setAttribute("data-kt-indicator", "off");
        }

        return {
            dateConvert, libTools,
            theReservation,
            theHotel,
            arrPaxes,
            printModal,
            refModal,
            click_ExportToPdf, refBtnExportToPdf,
            refFileUpload, refBtnSendEmail, refSendEmail,
            onSuccessUploadFile, onErrorUploadFile,
            click_SendEmail, emailEntered,
            theHotelDetails,

            voucherItem,
            submitButtonRefConfirm,
            submitButtonRefChange,
            submitButtonRefCancel,
            clickConfirm,
            getReservationEmail,
            showVoucherEmail,
            hideVoucherEmail,
        };
    }

});

