
import {defineComponent, ref} from "vue";
import PaxTransferDetail from "@/components/new-reservation-manuel/transfer/PaxTransferDetail.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
    name: "reservation-transfer-update-modal",
    components: {
        PaxTransferDetail,
    },
    methods: {
        setContent(prmTransferInfo) {
            console.log("Res setContent "+JSON.stringify(prmTransferInfo));
            (this.refComp_PaxTransferDetail as any).setContent(prmTransferInfo);
        },
    },
    setup() {

        let refComp_PaxTransferDetail = ref(null);

        function click_Update() {
            processSend();
        }

        function processSend() {
            let rv = {isok : true, result: {paxtransferdetail: {} }};

            if (rv.isok){

                if (rv.isok) {
                    let resA  = (refComp_PaxTransferDetail.value as any).getResults();
                    if (resA.isok) {
                        rv.result.paxtransferdetail = resA.result;
                    } else {
                        rv.isok = false;
                    }
                }

            }

            //SUCCESS
            if (rv.isok) {
                console.log("processSend SUCCESS DONE "+JSON.stringify(rv));

                const vvPayload = {
                    ProductType: "TRANSFER",
                    ID: (rv.result.paxtransferdetail as any).reservationProductId,
                    TotalCost: (rv.result.paxtransferdetail as any).transferCost,
                    TotalCurCost: (rv.result.paxtransferdetail as any).transferCost,

                    TotalPrice: (rv.result.paxtransferdetail as any).transferPayment,
                    TotalAccountPrice: (rv.result.paxtransferdetail as any).transferPayment,

                    RemarksJSON1: JSON.stringify((rv.result.paxtransferdetail as any).RemarksJSON1),
                    RemarksJSON3: JSON.stringify((rv.result.paxtransferdetail as any).RemarksJSON3),
                };

                store.dispatch(Actions.RESERVATION_PRODUCTS_EDIT, vvPayload).then((prmData) => {
                    if (prmData.status===200) {
                        Swal.fire('Güncellendi!', 'Kayit günceleme işlemi tamamlandı.', 'success').then((result)=>{
                            location.reload();
                        });
                    } else {
                        libTools.method.swalError("Tamam", "Hata Oluştu");
                    }
                }).catch(() => {
                    libTools.method.swalError("Tamam", "Hata Oluştu");
                });
            }
        }

        return {
            refComp_PaxTransferDetail,
            click_Update,
        };
    },

});
