
import {computed, defineComponent, ref,} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import { dateConvert } from "@/core/filters/datatime";



export default defineComponent({
  name: "operation-transfer-company-update",
  components: {
  },


  props: {
    itemID: String,
  },

  setup(props, ctx) {

    store.dispatch(Actions.ACCOUNT_LIST_ALL, {});

    store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});

    store.dispatch(Actions.RESERVATIONS_ALL_DETAILS, {'ID': props.itemID});

    let isEmit = true;

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const ModalRef = ref<null | HTMLElement>(null);

    const myDetails = computed(() => {
      let dataComputed =store.getters.reservationsDetails;
      detailTaken(dataComputed);
      return dataComputed;
    })

    const detailTaken = (prmDetail) => {
      if (prmDetail.ID!==undefined) {
        if (isEmit) {
          //isEmit = false;
          ctx.emit('detailChanged', prmDetail);
        }
      }
    }


    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }


      const payload1 = {
        ID: props.itemID,
        AccountID: myDetails.value.AccountID,
        Department: myDetails.value.Department,
        AccountVoucherNo: myDetails.value.AccountVoucherNo,
        ReservationNote: myDetails.value.ReservationNote,
      }

      store.dispatch(Actions.RESERVATIONS_EDIT, payload1)
          .then(() => {
            isEmit = true;
            store.dispatch(Actions.RESERVATIONS_ALL_DETAILS, {'ID': props.itemID});
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde işlemi tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(ModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getReservationsErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });
    }

    return {
      libTools, dateConvert,
      submitButtonRef,
      ModalRef,
      submit,
      myDetails,

    };
  },
});
